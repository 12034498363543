<template>
  <div class="login_col" id="formSection">
      <div class="login_col_inner">
        <!--  Login Form  -->
        <!--   Loader section -->
        <div class="form_loader" :class="{'visible':addUsernamePreloader || addPassPreloader}">
          <div class="form_loader_bar"></div>
        </div>

        <!--  END Loader section -->

        <!--  Logo and top text  -->
        <AccountManagementLogo>{{ $t('loginHeadingMsg') }}</AccountManagementLogo>

        <!--   Username Form -->
        <form v-if="!passwordFieldVisible" id="frmLoginStepOne">
          <div class="alert alert-danger alert-dismissible" v-if="usernameError" id="usernameErrorDiv">
            <a href="#" class="alert_close" data-dismiss="alert" aria-label="close">×</a>
            <p>{{ usernameErrorText }} </p>
          </div>
          <div class="form_group" :class="{'noplaceholder':usernamePlaceholder, 'has_error': $v.username.$error}">
            <label class="placeholder" for="username">{{ $t('usernameFieldText') }}</label>
            <input type="text" class="form_control" v-model="$v.username.$model" id="username"
                   @focus="usernameNoPlaceHolder = true" @blur="usernameNoPlaceHolder = false" required>
          </div>


          <div class="form_group" :class="{'preloader_on':addUsernamePreloader}">
            <input type="submit" id="loginStepOneSubmitButton" @click.prevent="showPasswordForm()"
                   v-model="loginStepOneButtonText" :disabled="$v.username.$error"/>
            <div class="cray_spinner"></div>
          </div>

        </form>

        <!--   Password Form -->
        <form v-else id="frmLoginStepTwo">
          <div class="alert alert-danger alert-dismissible" v-if="passwordError" id="passwordErrorDiv">
            <a href="#" class="alert_close" data-dismiss="alert" aria-label="close">×</a>
            <p>{{ passwordErrorText }}</p>
          </div>

          <div class="form_group input_readonly" @click="passwordFieldVisible = false">
            <input type="text" v-model="username" required readonly>
            <span class="edit_input"></span>
          </div>

          <div class="form_group" :class="{'noplaceholder':passwordPlaceholder, 'has_error':$v.password.$error}">
            <label class="placeholder" for="password">{{ $t('passwordLabelText') }}</label>
            <input type="password" class="form_control" v-model="$v.password.$model" id="password"
                   @focus="passwordNoPlaceHolder = true"
                   @blur="passwordNoPlaceHolder = false"
                   ref="password" required>
          </div>

          <div class="form_group" :class="{'preloader_on':addPassPreloader}">
            <input type="submit" id="loginStepTwoSubmitButton" @click.prevent="submitLoginForm()"
                   v-model="loginStepTwoButtonText" :disabled="$v.password.$error">
            <div class="cray_spinner"></div>
          </div>
        </form>

        <!--   Footer links and buttons -->
        <PageFooterCommon :show-register-link="true" :show-reset-link="true" :redirect="redirect" :service="service"></PageFooterCommon>
      </div>
  </div>
</template>

<script>

import AccountManagementLogo from "@/components/Logo/AccountManagement/AccountManagementLogo";
import PageFooterCommon from "@/components/AccountManagement/PageFooterCommon";
import Cookies from "js-cookie"

//Custom validation rules
import {email, required, minLength, maxLength} from 'vuelidate/lib/validators'

export default {
  props: ['redirect','service'],
  name: "LoginForm",
  components: {
    AccountManagementLogo,
    PageFooterCommon,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      loginStepOneButtonText: "Continue",
      loginStepTwoButtonText: "Submit",
      username: "",
      password: "",
      passwordFieldVisible: false,
      passwordError: false,
      usernameNoPlaceHolder: false,
      passwordNoPlaceHolder: false,
      usernameError: false,
      usernameErrorText: "",
      addUsernamePreloader: false,
      addPassPreloader: false,
      country:  this.$country,
      passwordErrorText:"",
    }
  },
  computed: {
    usernamePlaceholder: function () {
      if (this.usernameNoPlaceHolder || this.username.length > 0) {
        return true
      }
      return false;
    },
    passwordPlaceholder: function () {
      if (this.passwordNoPlaceHolder || this.password.length > 0) {
        return true
      }
      return false;
    }
  },
  methods: {
    showPasswordForm: function () {
      let self = this;

      //Show preloader before username check
      this.addUsernamePreloader = true;

      //verify the user exists
      let updatedHeaders = {
        'locale': this.$language,
        'country': this.$country,
        'service': this.service ? this.service : this.$serviceCode
      }
      this.$axios.post(`${this.$authApi}/users/check-if-username-exists`, {
        username: this.username,
        redirect_url: this.redirect ? this.redirect : ""
      }, {
        headers: updatedHeaders
      }).then(function (response) {
        if (response.status === 200) {
          //get the data
          let data = response.data;
          if (data.status) {
            if(!data.data.has_set_password){
              self.$router.push({
                path: '/users/set-new-password',
                query: {
                  new_password: '1',
                  redirect: data.data.redirect_url,
                  new_password_token: data.data.new_password_token
                }
              })
            }else {
              self.passwordFieldVisible = true
              self.addUsernamePreloader = false
            }
          } else {
            self.usernameErrorText = data.message;
            self.usernameError = true
            self.addUsernamePreloader = false
          }
        } else {
          //show error
        }
      }).catch(function (error) {
        //handle the error
        console.log(error)
      })
    },
    submitLoginForm: function () {
      let self = this;
      //this.passwordError = true;

      //show the preloader
      this.addPassPreloader = true

      //create the headers
      let updatedHeaders = {
        'locale': this.$language,
        'country': this.$country,
        'service': this.service ? this.service : this.$serviceCode
      }

      //get the form data
      let dataToSubmit = {
        'username': this.username,
        'password': this.password,
      }

      //do the actual login
      //push to api
      this.$axios.post(`${this.$authApi}/users/login`, dataToSubmit, {
        headers: updatedHeaders
      }).then(function (response) {
        if (response.status === 200) {
          self.addPassPreloader = false
          //get the data
          let data = response.data;

          if (data.status) {
            //clear the error
            self.passwordError = false
            self.passwordErrorText = ""
            let token = data.data.token
            let domain = process.env.COOKIE_DOMAIN;

            //set the cookie
            Cookies.set('_token', token, { expires: 7, domain: domain, secure: true, httpOnly:true});
            //check if the redirect url already has parameters
            let redirectUrl = self.redirect ? `${self.redirect}` : `${self.$defaultRedirect}`

            if(redirectUrl.includes('?')){
              redirectUrl = redirectUrl + "&"
            }else{
              redirectUrl = redirectUrl + "?"
            }

            //redirect to the requesting page
            window.location.href = `${redirectUrl}_token=${token}`
          }else{
              self.passwordError = true
              self.passwordErrorText = data.message
          }
        }
      });
    },
    removePlaceHolder: function () {
      this.noPlaceHolder = true
    },
    focusInput: function () {
      this.$refs.password.focus();
    }
  },
  validations: {
    username: {
      required,
      email,
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    password: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(8),
    }
  },
}
</script>

<style scoped>

</style>
